<template>
  <div>
    <a-card title="入库单创建">
      <a-button
        slot="extra"
        type="primary"
        ghost
        @click="
          () => {
            this.$router.go(-1);
          }
        "
      >
        <a-icon type="left" />返回</a-button
      >
      <section>
        <a-spin :spinning="loading">
          <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 7 }" :wrapper-col="{ span: 16 }">
            <a-row>
              <a-col :span="6" style="width: 320px">
                <a-form-model-item prop="number" label="入库单号">
                  <a @click="jumpStockInOrderDetail">{{ info.number }}</a>
                </a-form-model-item>
              </a-col>

              <a-col :span="6" style="width: 320px">
                <a-form-model-item prop="type_display" label="入库类型">
                  {{ info.type_display }}
                </a-form-model-item>
              </a-col>
              <a-col v-if="info.type === 'purchase'" :span="6" style="width: 320px">
                <a-form-model-item label="采购合同">
                  <a @click="jumpPurchaseOrderDetail">{{ info.purchase_order_number }}</a>
                </a-form-model-item>
              </a-col>
              <a-col v-if="info.type === 'sales_return'" :span="6" style="width: 320px">
                <a-form-model-item label="销售退货单据">
                  <a @click="jumpSalesReturnOrderDetail">{{ info.sales_return_order_number }}</a>
                </a-form-model-item>
              </a-col>
              <a-col v-if="info.type === 'stock_transfer'" :span="6" style="width: 320px">
                <a-form-model-item label="调拨单据">
                  <a @click="jumpStockTransferOrderDetail">{{ info.stock_transfer_order_number }}</a>
                </a-form-model-item>
              </a-col>
              <a-col
                v-if="info.type === 'production_stock_in' || info.type === 'production_stock_return'"
                :span="6"
                style="width: 320px"
              >
                <a-form-model-item label="生产工单">
                  <a @click="jumpProductionOrderDetail">{{ info.production_order_number }}</a>
                </a-form-model-item>
              </a-col>

              <a-col :span="6" style="width: 320px">
                <a-form-model-item prop="handler" label="制单人">
                  <a-select v-model="form.handler" style="width: 100%">
                    <a-select-option v-for="item in handlerItems" :key="item.id" :value="item.id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="6" style="width: 320px">
                <a-form-model-item prop="handle_time" label="制单日期">
                  <a-date-picker v-model="form.handle_time" valueFormat="YYYY-MM-DD" style="width: 100%" />
                </a-form-model-item>
              </a-col>
              <a-col :span="6" style="width: 320px">
                <a-form-model-item prop="remark" label="备注">
                  <a-input v-model="form.remark" allowClear />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-table
              rowKey="id"
              size="small"
              :columns="columns"
              :data-source="goodsData"
              :pagination="false"
              :scroll="{ x: 1400 }"
            >
              <div slot="stock_in_quantity" slot-scope="value, item, index">
                <div v-if="item.isTotal">{{ value }}</div>
                <a-input-number v-else v-model="item.stock_in_quantity" :min="0" size="small"></a-input-number>
              </div>
              <div slot="warehouse" slot-scope="value, item, index">
                <a-select v-if="!item.isTotal" v-model="item.warehouse" size="small" style="width: 100%">
                  <a-select-option v-for="item in item.inventory_items" :key="item.warehouse" :value="item.warehouse">
                    {{ item.warehouse_name }} | 库存: {{ item.total_quantity }}
                  </a-select-option>
                </a-select>
              </div>
              <div slot="batch_number" slot-scope="value, item, index">
                <a-input
                  v-if="!item.isTotal"
                  :disabled="!item.editable_batch_number"
                  v-model="item.batch_number"
                  size="small"
                ></a-input>
              </div>
              <div slot="production_date" slot-scope="value, item, index">
                <a-date-picker
                  v-if="!item.isTotal"
                  v-model="item.production_date"
                  :disabled="!item.enable_shelf_life"
                  valueFormat="YYYY-MM-DD"
                  size="small"
                  style="width: 100%"
                />
              </div>
              <div slot="action" slot-scope="value, item, index">
                <a-button-group v-if="!item.isTotal" size="small">
                  <a-button type="danger" @click="removeProduct(index)">移除</a-button>
                </a-button-group>
              </div>
            </a-table>
          </a-form-model>
        </a-spin>
        <div style="margin-top: 32px">
          <a-popconfirm title="确定创建吗?" @confirm="create">
            <a-button type="primary" :loading="loading">创建</a-button>
          </a-popconfirm>
        </div>
      </section>
    </a-card>
  </div>
</template>

<script>
import { userOption, warehousesOption } from "@/api/option";
import { stockInOrderDetail, stockInCreate } from "@/api/warehouse";
import NP from "number-precision";
import moment from "moment";

export default {
  data() {
    return {
      loading: true,
      info: {},
      form: {},
      rules: {
        handler: [{ required: true, message: "请选择经手人", trigger: "change" }],
        handle_time: [{ required: true, message: "请选择处理日期", trigger: "change" }],
      },
      warehouseItems: [],
      handlerItems: [],
      materialItems: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          width: 45,
          customRender: (value, item, index) => {
            return item.isTotal ? "合计" : index + 1;
          },
        },
        {
          title: "物料名称",
          dataIndex: "goods_name",
          key: "goods_name",
          width: 150,
        },
        {
          title: "物料编号",
          dataIndex: "goods_number",
          key: "goods_number",
          width: 150,
        },
        {
          title: "应入库数量",
          dataIndex: "total_quantity",
          width: 120,
        },
        {
          title: "待入库数量",
          dataIndex: "remain_quantity",
          width: 120,
        },
        {
          title: "入库数量",
          dataIndex: "stock_in_quantity",
          key: "stock_in_quantity",
          width: 120,
          scopedSlots: { customRender: "stock_in_quantity" },
        },
        {
          title: "推荐仓库",
          dataIndex: "warehouse",
          key: "warehouse",
          width: 200,
          scopedSlots: { customRender: "warehouse" },
        },
        {
          title: "物料批次",
          dataIndex: "batch_number",
          key: "batch_number",
          width: 120,
          scopedSlots: { customRender: "batch_number" },
        },
        {
          title: "生产日期",
          dataIndex: "production_date",
          key: "production_date",
          width: 150,
          scopedSlots: { customRender: "production_date" },
        },
        {
          title: "保质期天数",
          dataIndex: "shelf_life_days",
          key: "shelf_life_days",
          width: 120,
        },
        {
          title: "单位",
          dataIndex: "unit_name",
          key: "unit_name",
          width: 80,
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          width: 80,
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  created() {
    this.initData();
  },
  computed: {
    goodsData() {
      // 统计合计
      let totalQuantity = 0,
        totalAmount = 0;
      for (let item of this.materialItems) {
        totalQuantity = NP.plus(totalQuantity, item.stock_in_quantity);
      }
      return [
        ...this.materialItems,
        {
          id: "-1",
          isTotal: true,
          name: "",
          stock_in_quantity: totalQuantity,
        },
      ];
    },
    userId() {
      return this.$store.state.user.id;
    },
  },
  methods: {
    initData() {
      warehousesOption({ page_size: 999999, is_active: true }).then((data) => {
        this.warehouseItems = data.results;
      });
      userOption({ page_size: 999999, is_active: true }).then((data) => {
        this.handlerItems = data.results;
      });
      stockInOrderDetail({ id: this.$route.query.id })
        .then((data) => {
          this.info = data;
          const productItems = data.stock_in_goods_items.map((item) => ({ ...item, warehouse: undefined }));
          for (const item of productItems) {
            item.total_quantity = item.stock_in_quantity;
            item.stock_in_quantity = item.remain_quantity;
            if (item.editable_batch_number) {
              item.batch_number = "B" + moment().format("YYYYMMDD");
            } else {
              item.batch_number = "N/A";
            }

            // 推荐仓库
            const inventoryItems = item.inventory_items.filter((item) => item.total_quantity > 0);
            inventoryItems.sort((a, b) => b.total_quantity - a.total_quantity);
            if (inventoryItems.length > 0) {
              const inventoryItem = inventoryItems[0];
              item.warehouse = inventoryItem["warehouse"];
            }
          }

          this.materialItems = productItems;
        })
        .finally(() => {
          this.loading = false;
        });

      this.form.handler = this.userId;
      this.form.handle_time = moment().format("YYYY-MM-DD");
    },
    removeProduct(index) {
      this.materialItems.splice(index, 1);
    },
    create() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let ifHasEmptyBatch = false;
          let ifHasEmptyAmount = false;
          this.materialItems.map((item) => {
            if (item.enable_batch_control && !item.batch_number) {
              ifHasEmptyBatch = true;
            }
            if (!item.stock_in_quantity) {
              ifHasEmptyAmount = true;
            }
          });
          if (ifHasEmptyAmount) {
            this.$message.warn("请输入入库数量");
            return false;
          }
          if (ifHasEmptyBatch) {
            this.$message.warn("请输入批次编号");
            return false;
          }

          if (this.materialItems.length === 0) {
            this.$message.warn("无入库物料");
            return false;
          }

          for (const item of this.materialItems) {
            if (!item.warehouse) {
              this.$message.warn("请选择仓库");
              return false;
            }
          }

          // this.loading = true;
          let formData = {
            ...this.form,
            stock_in_order: this.info.id,
            stock_in_record_goods_items: this.materialItems.map((item) => {
              return {
                stock_in_goods: item.id,
                stock_in_quantity: item.stock_in_quantity,
                batch_number: item.batch_number,
                production_date: item.production_date,
                warehouse: item.warehouse,
              };
            }),
          };
          stockInCreate(formData)
            .then((data) => {
              this.$message.success("创建成功");
              this.$router.push({ path: "/warehouse/inStock" });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    jumpStockInOrderDetail() {
      this.$router.push({ path: "/warehouse/inStock_detail", query: { id: this.info.id } });
    },
    jumpPurchaseOrderDetail() {
      this.$router.push({ path: "/purchasing/purchase_record_detail", query: { id: this.info.purchase_order } });
    },
    jumpSalesReturnOrderDetail() {
      this.$router.push({ path: "/sale/sale_return_detail", query: { id: this.info.sales_return_order } });
    },
    jumpStockTransferOrderDetail() {
      this.$router.push({ path: "/warehouse/allocationDetail_detail", query: { id: this.info.stock_transfer_order } });
    },
    jumpProductionOrderDetail() {
      this.$router.push({ path: "/production/detial", query: { id: this.info.production_order } });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
<style></style>
